const getters = {
    userInfo: state => state.user.userInfo,
    token: state => state.user.token,
    isLogin:state=>state.user.isLogin,
    tenantId:state=>state.user.tenantId,
    picImg:state=>state.user.userInfo.picImg,
    role_name:state=>state.user.userInfo.role_name,
    icon:state=>state.sysInfo.icon,
    title:state=>state.sysInfo.title,
    logo:state=>state.sysInfo.logo,
    otherInfo:state=>state.sysInfo.otherInfo,
    language:state=>state.sysInfo.language,
  }
  export default getters
