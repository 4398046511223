<!--
 * @Author: lxc 2211294390@qq.com
 * @Date: 2022-11-08 13:05:27
 * @LastEditors: lxc 2211294390@qq.com
 * @LastEditTime: 2022-11-11 15:50:48
 * @FilePath: \金冉实训平台-PC端\src\layout\components\top.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE cl
-->
<template>
  <div class="top_container">
    <div class="content flex a-center j-between">
      <div class="left flex a-center" style="flex: 7">
        <div class="flex a-center">
          <img
                  class="block"
                  :src="otherInfo.logoUrl"
                  alt=""
                  width="60"
                  height="60"
                  @click="home"
          />
          <div  style="font-size: 20px;color: white;margin-left: 20px;width: max-content">{{name}}</div>
        </div>


        <ul class="tab flex a-center" >
          <li
            :class="
              activeTab == item.name
                ? 'tab_item tab_item_active flex-column'
                : 'tab_item '
            "
            v-for="(item, index) in tabList"
            :key="index"
            @click="go(item.name)"
          >
            {{ $t(`menu.${item.name}`) }}

          </li>
        </ul>
      </div>

      <div class="right flex a-center j-between" style="flex: 3">
        <div class="m-l-10">
          <el-input placeholder="搜索" v-model="searchName" size="small" >
            <el-button
              slot="append"
              icon="iconfont icon-sousuo"
              @click="search"
            ></el-button>
          </el-input>
        </div>

        <div class="loginout flex a-center m-l-15" v-if="isLogin">
<!--          <el-avatar :size="42" :src="img + picImg" icon="el-icon-user-solid">-->
<!--          </el-avatar>-->
            <div style="font-size:16px;margin-top: 2.5px;width: max-content;" class="cursor-pointer" @click="go('myInformation')" >{{userInfo.showName}}【{{userInfo.role_name}}】</div>
            <u class="loginout-text m-l-15" @click.stop="logout">{{$t('sysInfo.logout')}}</u>
        </div>
        <div class="login_box flex a-center" v-else>
          <div class="register" @click="tiao('login')">{{$t('sysInfo.login')}}</div>
          <!-- <div class="register m-l-15" @click="tiao('register')">注册</div> -->
        </div>
<!--          <div style="cursor: pointer;color: white" @click="changeLanguage">-->
<!--              {{$i18n.locale==='zh'?'english':'中文'}}-->
<!--          </div>-->
      </div>
    </div>
  </div>
</template>

<script>

import website from "@/config/website";
import { mapGetters } from "vuex";
import {getSysInfo,PCColumnManagementInfo,footInfo} from '@/api/curriculum'
export default {
  data() {
    return {
      tabList: [
        // { title: "首页", name: "home", path: "home" },
        // { title: "课程案例库", name: "curriculum", path: "curriculum" },
        // { title: "基础模型库", name: "moxing", path: "moxing" },
        // { title: "资源中心", name: "recourseDownload", path: "recourse" },
        // { title: "虚拟仿真", name: "fangzhen", path: "fangzhen" },
        // { title: "关于我们", name: "about", path: "about" },
        // { title: "个人中心", name: "myInformation", path: "my" },
      ],
      sysInfo:{}  ,
      name: null,
      searchName:"",
      tempName:""
    };
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters(["isLogin", "userInfo", "tenantId", "picImg","otherInfo",'language']),
    activeTab() {
      return this.$route.name
    },

    img() {
      return  website.imgUrl+'497728-hehuang/'
    },
  },

  methods: {
   async init(){
      const  data1 = await PCColumnManagementInfo()
      this.tabList =   data1.data.data.map(e=>{
         return{
           title:e.title,
           name:e.name,
           path:e.path
         }})
        let nameList = await footInfo()
        this.name = nameList.data.data.records[0].name
    },
    search() {

         if(this.tempName!==this.searchName){
           const name = this.$route.name
           if( ["curriculum","recourse","virtualSimulation","panoramicVR"].includes(name))   this.$router.push({ query: { name: this.searchName } })
           else this.$go("curriculum", { name: this.searchName })
           this.tempName = this.searchName
         }




      // if (this.$route.name === "curriculum") {
      //
      //   this.$router.push({ query: { name: this.name } });
      // } else {
      //   if (this.name) {
      //     this.$go("curriculum", { name: this.name });
      //   }
      // }
    },
    go(name) {
      this.$go(name);
    },

    tiao(login) {
      this.$go("login");
    },
      changeLanguage(){
          this.$i18n.locale = this.$i18n.locale==='zh'?'en':'zh'
      },
    home() {},

    logout() {
      this.$confirm("确定退出当前账户吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          this.$go("home");
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top_container {
  width: 100%;
  min-width: 1400px;

  background-color: #12368E;
  .content {
    margin: 0 auto;
    padding: 0px 10px 0 50px;
    .left {
      .tab {
        margin-left: 131px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
          width: 100%;
        .tab_item {
         min-width: 130px;
          height: 100%;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: white;
          text-align: center;
          cursor: pointer;
          transition: all 0.5s;
          justify-content: center;
          display: flex;
          align-items: center;
          &:hover {
            color: rgba($color: #1769fe, $alpha: 0.99);
          }
        }

        .tab_item_active {
          color: rgba($color: #1769fe, $alpha: 0.99);
          position: relative;
          transition: all 0.5s;
          background: linear-gradient(180deg, #0C1A4E, rgba(17,52,137,0));
        }
      }
    }
  /*  &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: #1769fe;
      border-radius: 2px;
      display: inline-block;
      position: absolute;
      bottom: -9px;
      transition: all 0.5s;
    }*/
    .right {
      .login_box {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        margin-left: 85px;
        cursor: pointer;

        .register {
          width: 100px;
          height: 38px;
          text-align: center;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 38px;

          border-radius: 19px;
          background: #1769fe;
        }
      }

      .loginout {
          color: white;
        .loginout-text {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-decoration: underline;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
