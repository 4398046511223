import Vue from "vue";
import en from './en'
import zh from './zh'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);   // 全局注册国际化包


const i18n = new VueI18n({
    locale: "zh",   // 初始化中文
    messages: {
        "zh":zh,
        "en":en
    }
});

export default i18n
