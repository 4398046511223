/*
 * @Author: lxc 2211294390@qq.com
 * @Date: 2022-11-08 11:29:19
 * @LastEditors: lxc 2211294390@qq.com
 * @LastEditTime: 2022-11-11 11:36:29
 * @FilePath: \金冉实训平台-PC端\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout";
import { setStore, getStore } from "@/util/store";
import { MessageBox } from "element-ui";
import { go } from "@/util/util";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/pages/home/index"),
        meta: {
          title: "首页",
        },
      },

      // {
      //   path: 'recourse',
      //   name: 'recourseDownload',
      //   component: () => import('@/pages/pdf'),
      //   meta: {
      //     title: '资源库',
      //     isAuth: false
      //   },
      // },
      //--------
      {
        path: "recourse",
        name: "recourse",
        component: () => import("@/pages/recourse/index"),
        meta: {
          title: "基础款模型",
          isAuth: false,
        },
      },
      {
        path: "resourcePool",
        name: "resourcePool",
        component: () => import("@/pages/resourcePool/index"),
        meta: {
          title: "数字资源中心",
          isAuth: false,
        },
      },
      {
        path: "recourseDetail",
        name: "recourseDetail",
        component: () => import("@/pages/resourcePool/detail"),
        meta: {
          title: "数字资源中心",
          isAuth: true,
        },
      },

      {
        path: "recourse",
        name: "fangzhen",
        component: () => import("@/pages/recourse/index"),
        meta: {
          title: "虚拟仿真",
          isAuth: false,
        },
      },

      {
        path: "about",
        name: "about",
        component: () => import("@/pages/about"),
        meta: {
          title: "关于我们",
        },
      },

      {
        path: "virtualSimulation",
        name: "virtualSimulation",
        component: () => import("@/pages/virtualSimulation"),
        meta: {
          title: "虚拟仿真",
        },
      },
      {
        path: "virtualSimulation/detail",
        name: "virtualSimulationDetail",
        component: () => import("@/pages/virtualSimulation/detail.vue"),
        meta: {
          title: "实训详情",
        },
      },
      {
        path: "panoramicVR",
        name: "panoramicVR",
        component: () => import("@/pages/panoramicVR"),
        meta: {
          title: "全景VR",
        },
      },
      //-------
      {
        path: "curriculum",
        name: "curriculum",
        component: () => import("@/pages/curriculum/index"),
        meta: {
          title: "在线课程",
        },
      },

      {
        path: "/curriculum/Info",
        name: "curriculumInfo",
        component: () => import("@/pages/curriculum/info"),
        meta: {
          title: "在线实训详情",
        },
        redirect: "/curriculum/Info/introduce",

        children: [
          {
            path: "/curriculum/Info/introduce",
            name: "introduce",
            component: () => import("@/pages/curriculum/introduce"),
            meta: {
              title: "课程介绍",
              isAuth: true,
            },
          },

          {
            path: "/curriculum/Info/comment",
            name: "comment",
            component: () => import("@/pages/curriculum/comment"),
            meta: {
              title: "课程评论",
            },
          },
          {
            path: "/curriculum/Info/download",
            name: "download",
            component: () => import("@/pages/curriculum/download"),
            meta: {
              title: "课程下载",
              isAuth: true,
            },
          },
        ],
      },

      {
        path: "curriculumList",
        name: "curriculumList",
        component: () => import("@/pages/curriculum/curriculumList"),
        meta: {
          title: "在线课程",
          isAuth: true,
          keepAlive: false
        },
      },

      {
        path: "my",
        name: "my",
        component: () => import("@/pages/my/index"),
        meta: {
          title: "我的",
          isAuth: true,
        },
        redirect: "/my/Information",

        children: [
          {
            path: "/my/Information",
            name: "myInformation",
            component: () => import("@/pages/my/myInformation"),
            meta: {
              title: "我的信息",
              isAuth: true,
            },
          },
          {
            path: "/my/updatePass",
            name: "updatePass",
            component: () => import("@/pages/my/updatePass"),
            meta: {
              title: "我的信息-修改密码",
              isAuth: true,
            },
          },
          {
            path: "/my/mycourse",
            name: "mycourse",
            component: () => import("@/pages/my/mycourse"),
            meta: {
              title: "我的信息-我的课程",
              isAuth: true,
            },
          },
          {
            path: "/my/collection",
            name: "collection",
            component: () => import("@/pages/my/collection"),
            meta: {
              title: "我的信息-我的收藏",
              isAuth: true,
            },
          },
          {
            path: "/my/fabulous",
            name: "fabulous",
            component: () => import("@/pages/my/fabulous"),
            meta: {
              title: "我的信息-我的点赞",
              isAuth: true,
            },
          },

          {
            path: "/my/training",
            name: "myTraining",
            component: () => import("@/pages/my/training"),
            meta: {
              title: "我的信息-我的实训",
              isAuth: true,
            },
          },
          {
            path: "/my/trainingDetail",
            name: "trainingDetail",
            component: () => import("@/pages/my/trainingDetail"),
            meta: {
              title: "我的信息-实训详情",
              isAuth: true,
            },
          },
          {
            path: "/my/task",
            name: "task",
            component: () => import("@/pages/my/task"),
            meta: {
              title: "我的信息-我的作业",
              isAuth: true,
            },
          },
          {
            path: "/my/taskDetail",
            name: "taskDetail",
            component: () => import("@/pages/my/taskDetail"),
            meta: {
              title: "作业详情-我的作业",
              isAuth: true,
            },
          },
          {
            path: "/my/examination",
            name: "examination",
            component: () => import("@/pages/my/examination"),
            meta: {
              title: "我的信息-我的考试",
              isAuth: true,
            },
          },
          {
            path: "/my/examinationRecord",
            name: "examinationRecord",
            component: () => import("@/pages/my/examinationRecord"),
            meta: {
              title: "考试记录-我的考试",
              isAuth: true,
            },
          },
          {
            path: "/my/comment",
            name: "myComment",
            component: () => import("@/pages/my/comment"),
            meta: {
              title: "我的信息-我的评论",
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/do",
    name: "do",
    component: () => import("@/pages/exam/paper/do"),
    meta: {
      title: "试卷答题",
    },
  },
  {
    path: '/read',
    name: 'read',
    component: () => import('@/pages/exam/paper/read'),
    meta: {
      title: '试卷详情',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/index'),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/panoramicVRDetail",
    name: "panoramicVRDetail",
    component: () => import("@/pages/panoramicVR/detail"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/preview",
    component: () => import(/* webpackChunkName: "page" */ "@/pages/preview"),
  },

  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "page" */ "@/components/error-page/404"),
    name: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    if (!getStore({ name: "token" })) {
      MessageBox.confirm("请先登录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        go("login");
      });
      return;
    }
  }

  if (to.meta.title) {
    document.title = to.meta.title + "-" + getStore({ name: "title" });
  }

  next(); //放行
});

export default router;
