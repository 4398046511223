export  default {
    footer:{
        address:'地址',
        phone:'电话',
        email:'邮箱',
        webUrl:'网址',
        qqGroup:'QQ群'
    },
    menu:{
        home:'首页',
        curriculum:'课程案例库',
        recourse:'基础模型库',
        resourcePool:'数字资源中心',
        about:'关于我们',
        myInformation:'我的信息',
        updatePass:'修改密码',
        collection:'我的收藏',
        fabulous:'我的点赞',
        myTraining:'我的实训',
        myComment:'我的评论',
        task:'我的作业',
        panoramicVR:'全景VR',
        virtualSimulation:'虚拟仿真',
        examination:'我的考试',
        examinationRecord:'考试记录',
        mycourse:'我的课程'
    },
    sysInfo:{
        login:'登录',
        logout:'登出',
        sysName:'虚拟仿真实训系统',
        tjkc:'推荐课程案例库',
        tjmx:'推荐模型案例库',
        more:'查看更多',
        success:'操作成功'
    }














}
