import { setToken, setRefreshToken, removeToken, removeRefreshToken } from '@/util/auth'
import { Message } from 'element-ui'
import { setStore, getStore } from '@/util/store'
import { validatenull } from '@/util/validate'
import { loginByUsername, logout } from '@/api/login'
import md5 from 'js-md5'

const user = {
    state: {
        // tenantId: getStore({ name: 'tenantId' }) || '',
        tenantId:sessionStorage.getItem('tenantId') || '',
        userInfo: getStore({ name: 'userInfo' }) || [],
        token: getStore({ name: 'token' }) || '',
        isLogin: getStore({ name: 'isLogin' }) || ''
    },
    actions: {
        //根据用户名登录
        LoginByUsernames({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                loginByUsername("497728", userInfo.username, md5(userInfo.password), userInfo.type, userInfo.key, userInfo.code).then(res => {
                    const data = res.data;
                    if (data.error_description) {
                        Message({
                          message: data.error_description,
                          type: 'error'
                        })
                        return
                      } else {
                        commit('SET_TOKEN', data.access_token);
                        // commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_TENANT_ID', data.tenant_id);
                        commit('SET_USER_INFO', data);
                        commit('SET_ISLOGIN',true)
                      }
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        },



        //刷新token
        // refreshToken({ state, commit }, userInfo) {
        //     window.console.log('handle refresh token');
        //     return new Promise((resolve, reject) => {
        //         refreshToken(state.refreshToken, state.tenantId,
        //             !validatenull(userInfo) ? userInfo.deptId : state.userInfo.dept_id,
        //             !validatenull(userInfo) ? userInfo.roleId : state.userInfo.role_id
        //         ).then(res => {
        //             const data = res.data;
        //             commit('SET_TOKEN', data.access_token);
        //             commit('SET_REFRESH_TOKEN', data.refresh_token);
        //             commit('SET_USER_INFO', data);
        //             resolve();
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    commit('SET_TOKEN', '');
                    // commit('SET_REFRESH_TOKEN', '');
                    // commit('SET_TENANT_ID', '');
                    // commit('SET_USER_INFO', {});
                    commit('SET_ISLOGIN', false);


                    resolve();
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //注销session
        // FedLogOut({ commit }) {
        //     return new Promise(resolve => {
        //         commit('SET_TOKEN', '');
        //         commit('SET_MENU_ALL_NULL', []);
        //         commit('SET_MENU', []);
        //         commit('SET_ROLES', []);
        //         commit('SET_TAG_LIST', []);
        //         commit('DEL_ALL_TAG');
        //         commit('CLEAR_LOCK');
        //         removeToken();
        //         removeRefreshToken();
        //         resolve();
        //     })
        // },
    },
    mutations: {
        SET_TOKEN: (state, token) => {


            setToken(token);
            state.token = token;
            setStore({ name: 'token', content: state.token, type: 1 })
        },

        SET_REFRESH_TOKEN: (state, refreshToken) => {
            setRefreshToken(refreshToken)
            state.refreshToken = refreshToken;
            setStore({ name: 'refreshToken', content: state.refreshToken, type: 1 })
        },
        SET_TENANT_ID: (state, tenantId) => {
            state.tenantId = tenantId;
            setStore({ name: 'tenantId', content: state.tenantId, type: 1 })
        },
        SET_USER_INFO: (state, userInfo) => {
            if (validatenull(userInfo.avatar)) {
                userInfo.avatar = "/img/bg/img-logo.png";
            }
            state.userInfo = userInfo;
            setStore({ name: 'userInfo', content: state.userInfo, type: 1 })
        },

        SET_ISLOGIN: (state, isLogin) => {


            state.isLogin = isLogin;
            setStore({ name: 'isLogin', content: state.isLogin, type: 1 })
        }
    }

}
export default user
