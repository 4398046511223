<!--
 * @Author: lxc 2211294390@qq.com
 * @Date: 2022-11-08 13:05:37
 * @LastEditors: lxc 2211294390@qq.com
 * @LastEditTime: 2022-11-09 17:54:59
 * @FilePath: \金冉实训平台-PC端\src\layout\components\footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="foot_container">
        <div class="content">
            <!-- 主办单位：某某某某有限公司 ****** 备案号<a href="http://beian.miit.gov.cn"  target="_blank" >******</a>联系我们
           -->

            <div class="content_1 flex j-between a-center">
                <img :src="otherInfo.logoUrl" width="130" height="130" alt="">
                <div >
                    <div> {{$t('footer.address')}}：{{otherInfo.contactAddress}}</div>
                    <div> {{$t('footer.phone')}}：{{otherInfo.phone}}　</div>
                    <div>{{$t('footer.email')}}：{{otherInfo.email}}</div>
                    <div>{{$t('footer.webUrl')}}：<a :href="otherInfo.webUrl" target="_blan">{{ otherInfo.webUrl }}</a></div>
                    <div>{{$t('footer.qqGroup')}}：{{otherInfo.qqGroup}}</div>
                    <div>{{otherInfo.copyrightInformation}}({{otherInfo.filingNo}})</div>
                </div>
                <img :src='otherInfo.qrCode' width="130" height="130" alt="">
            </div>

        </div>
<!--        <div class="foot_container_foot">-->
<!--            <a href="http://beian.miit.gov.cn" target="_blank">（辽ICP备18010831号）</a>-->

<!--        </div>-->
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {

        data(){
            return{
            }
        },
        methods:{

        },
        created() {
        },
        computed:{
            ...mapGetters(["otherInfo"]),
        }

    };

</script>

<style scoped lang="scss">
    .foot_container {
        width: 100%;
        //max-width: 1536px;
        min-width: 1400px;
        box-shadow: 0px 0px 1px 0px #dcdcdc;
        background-color: #12368E;
        margin-top: 112px;

        .content {
            width: 80%;
            max-width: 1536px;
            margin: 0 auto;
            text-align: center;
            padding: 45px 0 ;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bdbdbd;

            .content_1 {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                text-align: left;
                line-height: 30px;

            }
        }
        .foot_container_foot{
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #BDBDBD;
            background-color: white;
            text-align: center;
            padding: 9px 0 15px 0;
        }
    }
</style>
