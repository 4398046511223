import router from '@/router'
export const serialize = data => {
  let list = [];
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&');
};



export const go = (name, params = {}) => {


  router.push({
    name: name,
    query:{
      // tenantId:sessionStorage.getItem('tenantId'),
      ...params
    }
  },()=>{}, ()=>{}) ;

};
export const formatSeconds =  (theTime) =>{
  let theTime1 = 0
  let theTime2 = 0
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  let result = '' + parseInt(theTime) + '秒'
  if (theTime1 > 0) {
    result = '' + parseInt(theTime1) + '分' + result
  }
  if (theTime2 > 0) {
    result = '' + parseInt(theTime2) + '小时' + result
  }
  return result
}
export  const  format =  (array, key) =>{
  for (let item of array) {
    if (item.key === key) {
      return item.value
    }
  }
  return null
}
