import {getStore, setStore} from "@/util/store";

const sysInfo = {

    state: {
        logo: getStore({ name: 'logo' }) || '',
        icon: getStore({ name: 'icon' }) || '',
        title: getStore({ name: 'title' }) || '',
        otherInfo: getStore({ name: 'otherInfo' }) || '',
        language: getStore({ name: 'language' }) || 'zh',

    },
    actions:{
      GetSystemInfo({ commit }){


          // return new Promise((resolve,reject) =>{
          //
          //   setTimeout(()=>{
          //       commit('SET_LOGO','https://api.minio.jisoft.net/497728-hehuang/upload/20230517/1eb89301607c51b5ed970b58d0d7ab29.png')
          //       commit('SET_ICON','')
          //       commit('SET_TITLE','河湟数字资源库1')
          //   },0).catch(error => {
          //       reject(error);
          //   })
          //
          //
          // } )

      }
    },
     mutations : {







        SET_LOGO(state, logo) {
            state.logo = logo
            setStore({ name: 'logo', content: state.logo, type: 1 })

        },
        SET_ICON(state, icon) {
            state.icon = icon
            setStore({ name: 'logo', content: state.icon, type: 1 })

        },
        SET_TITLE(state, title) {
            state.title = title
            setStore({ name: 'title', content: state.title, type: 1 })

        },
         SET_LANGUAGE(state, language) {
            state.language = language
            setStore({ name: 'language', content: state.language, type: 1 })

        },
        SET_OTHER_INFO(state, otherInfo) {
            state.otherInfo = otherInfo
            setStore({ name: 'otherInfo', content: state.otherInfo, type: 1 })
        }
    },

}
export default sysInfo
