<!--
 * @Author: lxc 2211294390@qq.com
 * @Date: 2022-11-08 11:29:19
 * @LastEditors: lxc 2211294390@qq.com
 * @LastEditTime: 2022-11-11 13:49:13
 * @FilePath: \金冉实训平台-PC端\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import {footInfo} from '@/api/curriculum'
  export default {


  data(){
    return{
    }
  },

  mounted() {
    this.init();
  },
  created() {
    this.getSysInfo()




  },
  methods: {
    getSysInfo(){
      footInfo().then(res=>{
        const data = res.data.data.records[0]
        this.$store.commit('SET_TITLE',data.name)
        this.$store.commit('SET_LOGO',data.logoUrl)
        this.$store.commit('SET_OTHER_INFO',data)
        this.$store.commit('SET_LANGUAGE','zh')
        document.title=data.name
        this.changeIcon(data.logoUrl)
      })
    },
    changeIcon(url){
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/png';
      link.href = url
      document.head.appendChild(link);
    },
    init() {
      let ref = this.getQueryVariable("tenantId");
      sessionStorage.setItem("tenantId", ref);
    },
    getQueryVariable(variable) {
      let href = window.location.href;
      let query = href.substring(href.indexOf("?") + 1);
      let vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import url("@/assets/font/iconfont.css");

html,
body,
#app {
  height: 100%;

  /* 火狐 */
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;
}

// h2 {
//             /* 火狐 */
//             -moz-user-select: none;
//             /* Safari 和 欧朋 */
//             -webkit-user-select: none;
//             /* IE10+ and Edge */
//             -ms-user-select: none;
//             /* Standard syntax 标准语法(谷歌) */
//             user-select: none;
//         }



.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #12368E !important;
  color: #fff;
}
body>.el-select-dropdown{
  z-index: 10000 !important;
}
</style>
