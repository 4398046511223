/*
 * @Author: lxc 2211294390@qq.com
 * @Date: 2022-11-08 11:29:19
 * @LastEditors: lxc 2211294390@qq.com
 * @LastEditTime: 2022-11-11 13:45:09
 * @FilePath: \金冉实训平台-PC端\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss' // global css


Vue.config.productionTip = false
import 'reset-css';
import 'quickly-css/dist/quickly-css.min.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import {go} from "@/util/util"
// import VuePannellum from './components/vr/vr.vue'
// Vue.component('VPannellum', VuePannellum)
import VueI18n from 'vue-i18n'
import i18n from './lang'
Vue.use(VueI18n)

Vue.prototype.$go = go
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
