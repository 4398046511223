import request from '@/request/http';


//系统信息logo等
export const getSysInfo = () => {
  return request({
    url: '/api/home/PC',
    method: 'get',
  })
}

//栏目信息
export const PCColumnManagementInfo = () => {
  return request({
    url: '/api/home/PCColumnManagementInfo',
    method: 'get',
  })
}

//banner图信息
export const PCbannerInfo = () => {
  return request({
    url: '/api/home/PCbannerInfo',
    method: 'get',
  })
}
//footer信息
export const footInfo = () => {
  return request({
    url: '/api/eduwebsitemanagement/websitemanagement/list ',
    method: 'get',
  })
}








export const getList = (current, size, params) => {
  return request({
    url: '/api/skip/course/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


//首页----推荐课程分类
export const getWebsiteCourseNopage = () => {
  return request({
    url: '/api/skip/websiteCourse/nopage',
    method: 'get',
  })
}






//首页----推荐课程
export const getrecommendCourse = (params) => {
  return request({
    url: '/api/skip/course/recommendCourse',
    method: 'get',
    params: params
  })
}


//课程详情
export const getCourseDetail = (params) => {
  return request({
    url: '/api/skip/detail',
    method: 'get',
    params: params
  })
}


//课程详情--章节信息
export const getcourseKpoint = (params) => {
  return request({
    url: '/api/courseKpoint/courseKpoint/tree',
    method: 'get',
    params: params
  })
}

//课程评论 --列表
export const getCommentList = (current, size, params) => {
  return request({
    url: '/api/comment/comment/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


//课程评论新增
export const commentSave = (data) => {
  return request({
    url: '/api/comment/comment/save',
    method: 'post',
    data: data
  })
}


//课程详情---查询是否收藏
export const courseFavoritesifCollection = (data) => {
  return request({
    url: '/api/courseFavorites/courseFavorites/ifCollection',
    method: 'get',
    params: data
  })
}

//课程详情---查询是否收藏
export const courseFavoritessave = (data) => {
  return request({
    url: '/api/courseFavorites/courseFavorites/save',
    method: 'post',
    data: data
  })
}


//获取学员信息
export const getUserDetail = (params) => {
  return request({
    url: '/api/admin-user/user/detail',
    method: 'get',
    params: params
  })
}

//学院信息修改
export const userupdate = (data) => {
  return request({
    url: '/api/admin-user/user/update',
    method: 'post',
    data: data
  })
}
//课程tab
export const getSubjectXiao = (data) => {
  return request({
    url: '/api/skip/getSubjectXiao',
    method: 'get',
    params: data
  })
}
// 观看视频保存
export const getEducoursekpointplan = (data) => {
  return request({
    url: '/api/educoursekpointplan/coursekpointplan/submit',
    method: 'post',
    data: data
  })
}
// courseKpoint/courseKpoint/tree
// 我的课程tree

//我的 收藏


export const getCourseFavorites = (current, size, params) => {
  return request({
    url: '/api/admin-blade-course/course/favoritesCourse',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

//我的收藏 取消收藏
export const courseFavoritesRemove = (ids) => {
  return request({
    url: '/api/courseFavorites/courseFavorites/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

//我的评论
export const getMyComments = (current, size, params) => {
  return request({
    url: '/api/comment/comment/myComments',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
//我的作业
export const homework = (current, size, params) => {
  return request({
    url: '/api/eduhomeworksubmit/homeworksubmit/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
//作业详情
export const homeworkDetail = ( params) => {
  return request({
    url: '/api/eduhomeworksubmit/homeworksubmit/detail',
    method: 'get',
    params: params
  })
}
//作业详情
export const submitHomework = ( data) => {
  return request({
    url: '/api/eduhomeworksubmit/homeworksubmit/update',
    method: 'post',
    data
  })
}

//我的评论
export const commentsRemove = (ids) => {
  return request({
    url: '/api/comment/comment/remove',
    method: 'post',
    params:ids
  })
}


//修改密码
export const updatePassword = (data) => {
  return request({
    url: '/api/admin-user/user/update-password',
    method: 'post',
   params:data
  })
}



//资源----分类
export const getAttributeNoPage = () => {
  return request({
    url: '/api/attribute/attribute/nopage',
    method: 'get',
  })
}
//虚拟仿真-分页
export const virtualsimulationPage = (current, size, params) => {
  return request({
    url: '/api/eduvirtualsimulation/virtualsimulation/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const virtualsimulationDetail = (params) => {
  return request({
    url: '/api/eduvirtualsimulation/virtualsimulation/detail',
    method: 'get',
    params:params
  })
}


//全景vr-分页
export const panoramaPage = (current, size, params) => {
  return request({
    url: '/api/edupanorama/panorama/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


//关于我们
export const about = () => {
  return request({
    url: '/api/eduaboutus/aboutus/thelist',
    method: 'get',
  })
}


//资源列表
export const getResourceList = (current, size, params) => {
  return request({
    url: '/api/resource/resource/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

//资源列表
export const resourcepoolPage = (current, size, params) => {
  return request({
    url: '/api/eduresourcepool/resourcepool/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}




//资源详情
export const resourcepoolDetail = (params) => {
  return request({
    url: '/api/eduresourcepool/resourcepool/detailWeb',
    method: 'get',
    params: params
  })
}

//字典
export const dictionary = (code) => {
  return request({
    url: '/api/blade-system/dict-biz/dictionary',
    method: 'get',
    params:code
  })
}

//资源评分
export const commentsStarCount = (otherId) => {
  return request({
    url: '/api/comment/comment/commentsStarCount',
    method: 'get',
    params:{
      otherId:otherId
    }
  })
}





// 我的实训列表
export const realpracticesubmit = (current, size, params) => {
  return request({
    url: '/api/edurealpractice/realpractice/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 我的实训列表-任务列表
export const process = (current, size, params) => {
  return request({
    url: '/api/edurealpracticesubmit/realpracticesubmit/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 我的实训列表-任务列表-提交实训任务
export const subitRealpract = (current, size, params) => {
  return request({
    url: '/api/edurealpracticesubmit/realpracticesubmit/update',
    method: 'post',
    data:data
  })
}
//我的课程列表
export const myCourse = (current, size, params) => {
  return request({
    url: '/api/admin-blade-course/course/myCourse',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}



//虚拟仿真附件
export const vsfileList = (current, size, params) => {
  return request({
    url: '/api/eduvsfile/vsfile/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


//虚拟仿真操作步骤
export const vsseqList = (current, size, params) => {
  return request({
    url: '/api/eduvsseq/vsseq/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
//操作行为
export const vsverbsList = (current, size, params) => {
  return request({
    url: '/api/eduvsverbs/vsverbs/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
