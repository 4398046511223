import request from '@/request/http';

export const loginByUsername = (tenantId, username, password, type, key, code) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Dept-Id': '',
    'Role-Id':'',
      'Captcha-Key': key,
      'Captcha-Code': code,
    },
    params: {
      tenantId,
      username,
      password,
      grant_type: 'portal',
      scope: "all",
      type
    }
  });



export const refreshToken = (refresh_token, tenantId, deptId, roleId) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Dept-Id': (website.switchMode ? deptId : ''),
    'Role-Id': (website.switchMode ? roleId : '')
  },
  params: {
    tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});


export const logout = () => request({
  url: '/api/blade-auth/oauth/logout',
  method: 'get',
  authorization: false
});

export const getUserInfo = () => request({
  url: '/api/blade-auth/oauth/user-info',
  method: 'get'
});



export const clearCache = () => request({
  url: '/api/blade-auth/oauth/clear-cache',
  method: 'get',
  authorization: false
});
