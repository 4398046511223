<!--
 * @Author: lxc 2211294390@qq.com
 * @Date: 2022-11-08 13:05:15
 * @LastEditors: lxc 2211294390@qq.com
 * @LastEditTime: 2022-11-09 10:42:15
 * @FilePath: \金冉实训平台-PC端\src\layout\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: lxc 2211294390@qq.com
 * @Date: 2022-11-08 13:05:15
 * @LastEditors: lxc 2211294390@qq.com
 * @LastEditTime: 2022-11-08 13:06:01
 * @FilePath: \金冉实训平台-PC端\src\layout\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <top class="top" />
    <div style="height: 79px"></div>
    <div class="layout">
      <transition name="el-fade-in">
        <router-view  />
      </transition>
    </div>
    <foot />
  </div>
</template>

  <script>
import top from "./components/top.vue";
import foot from "./components/footer.vue";
export default {
  components: {
    top,
    foot,
  },


};
</script>

  <style lang="scss" scoped>
.layout {
  min-height: 680px;

}
    .top{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;


    }
</style>
