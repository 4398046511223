export  default {


    footer:{
        address:'address',
        phone:'phone',
        email:'email'
    },
    menu:{
        home:'home',
        curriculum:'courseCaseLibrary',
        recourse:'basicModelLibrary',
        resourcePool:'digitalResourceCenter',
        about:'aboutUs',
        myInformation:'myInformation',
        updatePass:'changePassword',
        collection:'myCollection',
        fabulous:'myLikes',
        myTraining:'myPracticalTraining',
        myComment:'myComment',
        task:'myHomework',
        panoramicVR:'panoramicVR',
        virtualSimulation:'virtualSimulation',
        examination:'examination',
        examinationRecord:'examinationRecord',
        mycourse:'mycourse'
    },
    sysInfo:{
        login:'login',
        logout:'logout',
        sysName:'hehuangDigitalResourceLibrary',
        tjkc:'recommendedCourses',
        tjmx:'recommendationModel',
        more:'more',
        success:'operationSucceeded'
    }




}
